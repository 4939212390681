<template>
  <NuxtLayout name="minimal">
    <div class="container mx-auto pb-16 lg:pb-24 pt-6 lg:pt-13">
      <h1 class="text-xl md:text-3xl text-primary-1-100 font-bold text-center md:text-left">
        {{ $t('checkout') }}
      </h1>
      <!-- <CheckoutSteps class="mt-6 px-5 lg:px-0" :current="currentStep" /> -->

      <div v-if="isPageLoading" class="h-full w-full">
        <Spinner class="w-12 h-12 mx-auto" />
      </div>

      <NuxtPage v-if="!isPageLoading" />
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
const { t: $t } = useI18n({
  useScope: 'local',
});
const { isFetchingCart } = useCartAttributes();
const { isPageLoading } = useSsrCoreApp([isFetchingCart]);
</script>

<style lang="postcss">
.Checkout__container {
  display: grid;
  grid-template-columns: 1fr;
}

@screen lg {
  .Checkout__container {
    grid-template-columns: 1fr 472px;
    column-gap: 60px;
  }
}
</style>

<i18n>
{
  "en": {
    "checkout": "Checkout",
    "fewStepsToGo": "Few Steps To Go",
    "backToCart": "Back To Cart"
  },
  "ar": {
    "checkout": "الدفع",
    "fewStepsToGo": "بضع خطوات على الذهاب",
    "backToCart": "العوده للسله"
  }
}
</i18n>
